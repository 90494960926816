<template>
  <v-dialog v-model="internalDialogState">
    <v-card v-if="aircraft !== null">
      <v-card-title class="headline">
        Aircraft Data - {{aircraft.aircraft.registration}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="7" cols="12">
            <v-row>
              <v-col md="12" cols="12">
                <v-data-table :items="flights" :headers="headers" :items-per-page="12" :sort-by="['start_time']" :sort-desc="[true]" :footer-props="{'items-per-page-options': [12]}">
                  <template #item.start_flight="{ item }">
                    <p>{{formatDate(item['start_time'])}}</p>
                  </template>
                  <template #item.in_flight_length="{ item }">
                    <p>{{msToTime(item['in_flight_length'])}}</p>
                  </template>
                  <template #item.actions="{ item }">
                    <div class="d-flex">
                      <v-btn color="success" v-on:click="openShare(item['id'])"  style="margin-right: 5px">View</v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="5" cols="12" class="d-flex flex-column">
            <v-row class="flex-grow-0">
              <v-col class="text-center">
                <p>Airline</p>
                <h2>{{aircraft.airline.name}}</h2>
              </v-col>
              <v-col class="text-center">
                <p>Airframe</p>
                <h2>{{aircraft.airframe.name}}</h2>
              </v-col>
              <v-col class="text-center">
                <p>Registration</p>
                <h2>{{aircraft.aircraft.registration}}</h2>
              </v-col>
            </v-row>
            <v-row class="flex-grow-1" style="min-height: 50vh">
              <l-map :zoom.sync="zoom" :center="mapCenter" :maxBoundsViscosity="1.0" :maxBounds="[[-90, -180], [90, 180]]" :options="{worldCopyJump: true, preferCanvas:true}" ref="map">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :icon="icon" :lat-lng="aircraftLatLng" v-if="!dataLoading"/>
                <l-circle-marker v-for="icaoData in airportMarkersStandard" :lat-lng="icaoData['lat-lng']" :radius="1">
                  <l-tooltip>{{icaoData['icao']}}</l-tooltip>
                </l-circle-marker>
                <l-circle-marker v-for="icaoData in airportMarkersZoom" :lat-lng="icaoData['lat-lng']" :radius="4">
                  <l-tooltip :options="{'permanent': true}">{{icaoData['icao']}}</l-tooltip>
                </l-circle-marker>
              </l-map>
            </v-row>
            <v-row class="flex-grow-0">
              <v-col class="text-center">
                <p>Location</p>
                <h2> <span v-if="lastFlight !== null">{{lastFlight.destination}}</span><span v-else>UNKNOWN</span></h2>
              </v-col>
              <v-col class="text-center">
                <p>Flight Count</p>
                <h2>{{aircraft['aircraft_flights'].length}}</h2>
              </v-col>
              <v-col class="text-center">
                <p>Airtime</p>
                <h2>{{flightTime}} Hours</h2>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions class="actionsContainer">
        <v-btn color="error" v-on:click="internalDialogState = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Shared from "@/components/Shared";
import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMarker,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip,
  LMap
} from "vue2-leaflet";
import "leaflet.geodesic"
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import L from "leaflet";
import airplaneIcon from "@/assets/icons/airplane.png";

export default {
  name: "FleetModal",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LCircleMarker,
    LControlAttribution,
    LIcon,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    LPolyline
  },
  props: ['dialogState', 'aircraftID', 'fleet'],
  mounted: function(){
    this.resetMap()
  },
  data: function(){
    return {
      dataLoading: true,
      currentLines: [],
      tripICAOs: [],
      airportData: {},
      zoom:3,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      }),
      headers: [
        {
          text: 'Callsign',
          value: 'callsign'
        },
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Origin',
          value: 'origin'
        },
        {
          text: 'Start Flight',
          value: 'start_flight'
        },
        {
          text: 'Destination',
          value: 'destination'
        },
        {
          text: 'In Flight Length',
          value: 'in_flight_length'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          width: '1%'
        }
      ]
    }
  },
  computed: {
    mapCenter: function(){
      const firstItem = this.tripICAOs[0]
      if (firstItem === undefined){
        return L.latLng(0,0)
      }

      let smallestLat = null
      let largestLat = null
      let smallestLon = null
      let largestLon = null

      for (let i = 0; i < this.tripICAOs.length; i++){
        let ref = this.tripICAOs[i]['lat-lng']
        const lat = ref[0]
        const lon = ref[1]

        if (smallestLat === null || lat < smallestLat){
          smallestLat = lat
        }
        if (largestLat === null || lat > largestLat){
          largestLat = lat
        }

        if (smallestLon === null || lon < smallestLon){
          smallestLon = lon
        }
        if (largestLon === null || lon > largestLon){
          largestLon = lon
        }
      }

      return L.latLng((smallestLat + largestLat)/2, (smallestLon + largestLon)/2)
    },
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    aircraft: function(){
      if (this.aircraftID === "" || this.fleet === undefined){
        return null
      }
      const aircraftFiltered = this.fleet.filter(a => a.aircraft.id === this.aircraftID)
      if (aircraftFiltered.length !== 1){
        return null
      }
      return aircraftFiltered[0]
    },
    flights: function(){
      if (this.aircraft == null){
        return []
      }
      return this.aircraft['aircraft_flights']
    },
    lastFlight: function(){
      const flights = this.aircraft['aircraft_flights']
      const flightCount = flights.length

      if (flightCount > 0){
        const latestFlight = flights[flightCount - 1]
        if (!latestFlight['in_flight']){
          return latestFlight
        }else if (flightCount > 1){
          return flights[flightCount - 2]
        }
      }

      return null
    },
    flightTime: function(){
      const flightTimes = this.aircraft['aircraft_flights'].map(d => d['in_flight_length'])
      return this.msToHours(flightTimes.reduce((a, b) => a+b, 0))
    },
    airportMarkersZoom: function(){
      if (this.zoom > 4){
        return this.tripICAOs
      }
      return []
    },
    airportMarkersStandard: function(){
      if (this.zoom <= 4){
        return this.tripICAOs
      }
      return []
    },
    aircraftLatLng: function(){
      const airportLocation = this.lastFlight.destination
      if (this.airportData[airportLocation] === undefined){
        return L.latLng(0,0)
      }
      const airport = this.airportData[airportLocation]
      return L.latLng(airport['airport_ref_latitude'], airport['airport_ref_longitude'])
    }
  },
  methods: {
    msToHours: function(msIn){
      return Shared.methods.msToHours(msIn)
    },
    msToTime: function(duration) {
      return Shared.methods.msToTime(duration)
    },
    openShare: function(flightID){
      let page = this.$router.resolve({name: "FlightShare", params: {flightID: flightID}})
      window.open(page.href, "_blank")
    },
    formatDate: function(input){
      let inDate = new Date(input)
      // Go has weird date before 1970 meaning null date is negative
      if (inDate.getTime() < 0){
        return "UNKNOWN"
      }
      return inDate.toLocaleString()
    },
    resetMap: function(){
      setTimeout(() => {
        let mapRef = this.$refs.map
        if (mapRef === undefined){
          return
        }
        mapRef.mapObject.invalidateSize()
      }, 0);
    },
    addCurve: function(lat1, lon1, lat2, lon2){
      let mapRef = this.$refs.map

      if (mapRef === undefined){
        return
      }

      mapRef = mapRef.mapObject

      const p1 = new L.LatLng(lat1, lon1);
      const p2 = new L.LatLng(lat2, lon2);
      const options = {
        color: 'green',
        steps: 8,
        weight: 1,
      }
      const geodesic = new L.Geodesic([p1, p2], options).addTo(mapRef)
      this.currentLines.push(geodesic)
    },
    clearMapLines: function(){
      let mapRef = this.$refs.map

      if (mapRef === undefined){
        return
      }

      mapRef = mapRef.mapObject

      // find removals ==> look over existing, if not in val then remove
      for(let i = 0; i < this.currentLines.length; i++){
        this.currentLines[i].remove(mapRef)
      }
      this.currentLines = []
    },
    drawLines: function(airports){
      for(let i = 0; i < this.flights.length; i++) {
        let flight = this.flights[i]
        let origin = airports[flight['origin']]
        let destination = airports[flight['destination']]
        if (origin === undefined || destination === undefined){
          console.log("Undefined origin or destination")
          continue
        }
        this.addCurve(origin['airport_ref_latitude'], origin['airport_ref_longitude'], destination['airport_ref_latitude'], destination['airport_ref_longitude'])
      }
    },
  },
  watch: {
    internalDialogState(dialogVisible){
      if (dialogVisible){
        let parent = this
        this.dataLoading = true
        this.resetMap()
        setTimeout(() => {
          let origins = this.flights.map(flight => flight['origin'])
          let destinations = this.flights.map(flight => flight['destination'])
          let airport_array = [... new Set(origins.concat(destinations))]
          return fetch(this.$baseURL + "/airport?airport=" + airport_array.join(","))
            .then((response) => {
              if (response.ok){
                return  response.json().then(d => {
                  if (d != null) {
                    let airports = {}
                    for (const [icao, data] of Object.entries(d)) {
                      airports[icao] = data
                      airports = Object.assign({}, airports)

                      parent.tripICAOs.push({
                        'lat-lng': [data['airport_ref_latitude'], data['airport_ref_longitude']],
                        'icao': data['identifier']
                      })
                    }
                    parent.airportData = Object.assign({}, airports)
                    parent.dataLoading = false
                    this.drawLines(airports)
                    this.$refs.map.mapObject.fitBounds(L.featureGroup(this.currentLines).getBounds(), {padding: [50,50]})
                  }
                })
              }
            })
        }, 10)
      }else{
        this.tripICAOs = []
        this.clearMapLines()
      }
    },
  }
}
</script>

<style scoped>
  .actionsContainer{
    display: flex;
    justify-content: flex-end;
  }

  .actionsContainer .v-btn{
    margin: 5px;
  }
</style>
