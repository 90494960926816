<template>
  <v-dialog v-model="internalDialogState" width="500">
    <v-card>
      <v-card-title class="headline">
        Aircraft
      </v-card-title>
      <v-card-text>
        <v-form ref="addAircraftForm">
          <v-autocomplete :items="airlines" item-value="id" item-text="name" :label="'Airline'" v-model="aircraftAirlineData" :rules="requiredFieldRule(aircraftAirlineData)" required/>
          <v-select :items="airframes" item-value="id" item-text="name" :label="'Airframe'" v-model="aircraftAirframeData" :rules="[() => !!aircraftAirframeData || 'This field is required']" required/>
          <v-text-field :label="'Registration'" v-model="aircraftRegistrationData" :rules="[() => !!aircraftRegistrationData || 'This field is required']" required/>
          <div class="actionsContainer">
            <template v-if="editState">
              <v-btn color="warning" v-on:click="editAircraft(aircraftID, aircraftAirlineData, aircraftAirframeData, aircraftRegistrationData)" :loading="saving">Update and close</v-btn>
            </template>
            <template v-else>
              <v-btn color="success" v-on:click="addAircraft(aircraftAirlineData, aircraftAirframeData, aircraftRegistrationData)" :loading="saving">Add and close</v-btn>
            </template>
            <v-btn color="error" v-on:click="internalDialogState = false" :disabled="saving">Close</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "@/components/Shared";

export default {
  name: "AddAircraftModal",
  props: ['dialogState', 'fleet', 'aircraftID', 'airframes', 'airlines'],
  data: function () {
    return {
      aircraftAirlineData: '',
      aircraftAirframeData: '',
      aircraftRegistrationData: '',
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function(){
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    internalFleet: {
      get: function (){
        return this.fleet
      },
      set: function (val){
        this.$emit('update:fleet', val)
      }
    },
    editState: function(){
      return this.aircraftID !== "";
    },
  },
  watch: {
    internalDialogState: function(val){
      if (val){
        this.onOpen()
      }else{
        this.onClose()
      }
    },
    aircraftID: function(val){
      if (val !== ""){
        const aircraftData = this.getAircraftOnID(val)
        this.aircraftAirlineData = aircraftData.airline.id
        this.aircraftAirframeData = aircraftData.airframe.id
        this.aircraftRegistrationData = aircraftData.aircraft.registration
      }
    }
  },
  methods: {
    onOpen: function(){
    },
    onClose: function(){
      this.aircraftAirlineData = ''
      this.aircraftAirframeData = ''
      this.aircraftRegistrationData = ''
      this.saving = false
      this.$refs.addAircraftForm.resetValidation()
    },
    requiredFieldRule: function(element){
      return Shared.methods.requiredFieldRule(element)
    },
    addAircraft: function(aircraftAirline, aircraftAirframe, aircraftRegistration){
      const formValid = this.$refs.addAircraftForm.validate()

      if (!formValid){
        return
      }
      this.saving = true

      const aircraft = {
        airlineID: aircraftAirline,
        airframeID: aircraftAirframe,
        registration: aircraftRegistration,
      }
      this.addAircraftCall(aircraft)
    },
    addAircraftCall: function(aircraft){
      let body = Object.assign({}, this.currentUser)
      body['aircraft'] = aircraft
      const bodyJson = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJson
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/addAircraft", requestOptions)
        .then(response => response.json().then(data => {
          if (response.ok){
            this.internalFleet = data['result']
            this.internalDialogState = false
          }else{
            console.error("An error occurred: " + data)
          }
        }))
    },
    editAircraft: function(aircraftID, aircraftAirline, aircraftAirframe, aircraftRegistration){
      const formValid = this.$refs.addAircraftForm.validate()

      if (!formValid){
        return
      }

      const aircraft = {
        airlineID: aircraftAirline,
        airframeID: aircraftAirframe,
        registration: aircraftRegistration
      }
      this.saving = true
      this.editAircraftCall(aircraftID, aircraft)
    },
    editAircraftCall: function(aircraftID, aircraft){
      let body = {
        aircraft: aircraft
      }
      const bodyJSON = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJSON
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/editAircraft?aircraftID="+aircraftID, requestOptions)
        .then(response => response.json().then(data => {
          if (!response.ok){
            console.error(data)
            return
          }
          this.internalFleet = data['result']
          this.internalDialogState = false
        }))
    },
    getAircraftOnID: function(aircraftID){
      const aircraftFilter = this.internalFleet.filter(d => d.aircraft.id === aircraftID)
      if (aircraftFilter.length < 1){
        return null
      }
      return aircraftFilter[0]
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.actionsContainer .v-btn{
  margin: 5px;
}
</style>
