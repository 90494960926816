<template>
  <v-container>
    <v-tabs centered v-model="tab">
      <v-tab>
        Aircraft
      </v-tab>
      <v-tab>
        Airframes & Custom Airlines
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <FleetAircraft :fleet.sync="fleet" :airframes="orderedAirframes" :airlines="airlines" :loading="isLoading"/>
      </v-tab-item>
      <v-tab-item>
        <FleetAirframeAirline :airframes.sync="orderedAirframes" :airlines.sync="airlines" :loading="isLoading"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import FleetAircraft from "../components/FleetAircraft";
import FleetAirframeAirline from "../components/FleetAirframeAirline";

export default {
  name: "Fleet",
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    orderedAirframes: {
      get: function() {
        return this.airframes.sort(function (a, b) {
          return a['name'].toUpperCase().localeCompare(b['name'].toUpperCase())
        })
      },
      set: function(val){
        this.airframes = val
      }
    },
    isLoading: function(){
      return this.loadingCount > 0
    }
  },
  components: {
    FleetAirframeAirline,
    FleetAircraft,
  },
  created: function (){
    this.getFleet()
    this.getAirframes()
    this.getAirlines()
  },
  data: function (){
    return {
      tab: null,
      fleet: [],
      airframes: [],
      airlines: [],
      loadingCount: 3,
    }
  },
  methods:{
    getAirlines: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }

      fetch(this.$baseURL + "/airlines?username="+ this.currentUser['user'], requestOptions)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              this.airlines = data
              this.loadingCount += -1
            })
          }
        })

    },
    getFleet: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/richUserFleet", requestOptions)
        .then(response => response.json())
        .then(function (data){
          if ('result' in data) {
            if (data['result'] != null) {
              this.fleet = data['result']
            }
            this.loadingCount += -1
          }
        }.bind(this))
    },
    getAirframes: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/getAirframes", requestOptions)
        .then(response => response.json())
        .then(function (data){
          this.airframes = data['result']['airframes']
          this.loadingCount += -1
        }.bind(this))
    },
  }
}
</script>

<style scoped>

</style>
