<template>
  <v-dialog v-model="internalDialogState" style="z-index: 20" width="500">
    <v-card>
      <v-card-title class="headline">
        Airframe
      </v-card-title>
      <v-card-text>
        <v-form ref="addAirframeForm">
          <v-text-field :label="'SimBrief Airframe ID'" v-model="airframeID" :rules="requiredFieldRule(airframeID)"/>
          <v-text-field :label="'Airframe Name'" v-model="airframeName" :rules="requiredFieldRule(airframeName)"/>
          <v-text-field :label="'ICAO'" v-model="icao" :rules="requiredFieldRule(icao)"/>
          <div class="actionsContainer">
            <template v-if="editState">
              <v-btn color="success" v-on:click="editAirframe(id, airframeID, airframeName, icao)">Edit and close</v-btn>
            </template>
            <template v-else>
              <v-btn color="success" v-on:click="addAirframe(airframeID, airframeName, icao)">Add and close</v-btn>
            </template>
            <v-btn color="error" v-on:click="internalDialogState = false" :disabled="saving">Close</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "@/components/Shared";

export default {
  name: "AddAirframeModal",
  props: ['dialogState', 'airframes', 'passedInAirframeID'],
  data: function(){
    return {
      id: "",
      airframeID: "",
      airframeName: "",
      icao: "",

      saving: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    internalAirframes: {
      get: function () {
        return this.airframes
      },
      set: function (val) {
        this.$emit('update:airframes', val)
      }
    },
    editState: function(){
      return this.passedInAirframeID !== ""
    }
  },
  watch: {
    passedInAirframeID: function(val){
      if (val !== ""){
        const airframesFiltered = this.airframes.filter(d => d.is_custom).filter(d => d.id === val)
        if (airframesFiltered.length > 0){
          const airframe = airframesFiltered[0]
          this.id = airframe.id
          this.airframeID = airframe.type
          this.airframeName = airframe.name
          this.icao = airframe.icao
        }
      }
    },
    internalDialogState: function(val){
      if (!val){
        this.$emit('update:passedInAirframeID', "")
        this.$refs.addAirframeForm.resetValidation()
        this.id = ''
        this.airframeID = ''
        this.airframeName = ''
        this.icao = ''
      }
    }
  },
  methods: {
    requiredFieldRule: function(element){
      return Shared.methods.requiredFieldRule(element)
    },
    addAirframe: function(airframeID, airframeName, icao){
      const formValid = this.$refs.addAirframeForm.validate()
      if (!formValid){
        return
      }

      let body = {
        airframeID: airframeID,
        airframeName: airframeName,
        icao: icao
      }
      const bodyJson = JSON.stringify(body)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJson
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/addAirframe", requestOptions)
        .then(response => {
          if (response.ok){
            response.json().then(data => {
              this.internalAirframes.push({
                id: data,
                type: airframeID,
                name: airframeName,
                is_custom: true,
                icao: icao
              })
              this.internalDialogState = false
            })
          }else{
            console.error("Failed adding airframe")
            console.error(response)
          }
        })
    },
    editAirframe: function(id, airframeID, airframeName, icao){
      const formValid = this.$refs.addAirframeForm.validate()
      if (!formValid){
        return
      }

      let body = {
        id: id,
        airframeID: airframeID,
        airframeName: airframeName,
        icao: icao
      }
      const bodyJson = JSON.stringify(body)
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: bodyJson
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/editAirframe", requestOptions)
        .then(response => response.json().then(data => {
          if (!response.ok){
            console.error(data)
            return
          }
          const error = data['error']
          if (error === ""){
            const indexToEdit = this.internalAirframes.findIndex(obj => obj.id === id)
            this.internalAirframes[indexToEdit].id = id
            this.internalAirframes[indexToEdit].type = airframeID
            this.internalAirframes[indexToEdit].name = airframeName
            this.internalAirframes[indexToEdit].icao = icao
            this.internalDialogState = false
          }
        }))
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.actionsContainer .v-btn{
  margin: 5px;
}
</style>
