<template>
  <v-container>
    <v-row v-mutate="mutateMap">
      <v-col md="7" cols="12">
        <v-card>
          <v-card-title>
            Aircraft Fleet
            <v-spacer></v-spacer>
            <div style="display: contents">
              <v-btn v-on:click="dialogState = true" color="success">Add Aircraft</v-btn>
            </div>

          </v-card-title>
          <v-data-table :items="internalFleet" :headers="headers" :search="search" :sort-by="['flight_time']" :sort-desc="[true]" :loading="loading" :items-per-page="15" :item-class="rowClass">
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn color="success" v-on:click="openViewAircraft(item.aircraft.id)" style="margin-right: 5px">View</v-btn>
                <v-btn color="warning" v-on:click="openEditAircraft(item.aircraft.id)"  style="margin-right: 5px">Edit</v-btn>
                <v-btn color="error" v-on:click="deleteAircraftWithPopup(item.aircraft.id);"  style="margin-right: 5px">Delete</v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="5" cols="12" class="d-flex flex-column">
        <v-row class="flex-grow-0">
          <v-col class="text-center">
            <p>Aircraft Count</p>
            <h2>{{this.internalFleet.length}}</h2>
          </v-col>
          <v-col class="text-center">
            <p>Airline Count</p>
            <h2>{{airlineUnique}}</h2>
          </v-col>
          <v-col class="text-center">
            <p>Aircraft Type Count</p>
            <h2>{{aircraftTypes}}</h2>
          </v-col>
        </v-row>
        <v-row class="flex-grow-1" style="min-height: 40rem">
          <l-map ref="mapFleet" :zoom.sync="zoom" :center="mapCenter" style="z-index: 0;" :options="{worldCopyJump: true}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

            <!-- Map is a bit weird, the internal components of marker aren't reactive so can't just have the inside of
            it change. Set the full markers. -->
            <l-marker v-for="aircraft in fleetStandard" :icon="icon" :lat-lng="getLatLng(aircraft['latLng'])">
              <l-tooltip>
                <p style="margin:0; padding:0;">
                  <strong>[{{aircraft['airport']['identifier']}}] {{aircraft['airport']['name']}}</strong>
                </p>
                <hr/>
                <p v-for="label in aircraft['label']" style="margin:0; padding:0;">
                  {{label}}
                </p>
              </l-tooltip>
            </l-marker>

            <l-marker v-for="aircraft in fleetShowLabels" :icon="icon" :lat-lng="getLatLng(aircraft['latLng'])">
              <l-tooltip :options="{'permanent': true}">
                <p style="margin:0; padding:0;">
                  <strong>[{{aircraft['airport']['identifier']}}] {{aircraft['airport']['name']}}</strong>
                </p>
                <hr/>
                <p v-for="label in aircraft['label']" style="margin:0; padding:0;">
                  {{label}}
                </p>
              </l-tooltip>
            </l-marker>

          </l-map>
        </v-row>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirmDialogRef"/>
    <AddAircraftModal :fleet.sync="internalFleet" :dialog-state.sync="dialogState" :aircraft-i-d="aircraftIDToEdit" :airframes="airframes" :airlines="airlines"/>
    <FleetModal :dialog-state.sync="aircraftDialogState" :fleet="internalFleet" :aircraft-i-d="aircraftIDToView" />
  </v-container>
</template>

<script>
import AddAirframeModal from "./AddAirframeModal";
import AddAircraftModal from "./AddAircraftModal";
import ConfirmDialog from "./ConfirmDialog";
import {mapGetters} from "vuex";
import FleetModal from "@/components/FleetModal";
import Shared from "@/components/Shared";
import {
  LCircleMarker,
  LMap,
  LMarker,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import L from "leaflet";
import airplaneIcon from "@/assets/icons/airplane.png";

export default {
  name: "FleetAircraft",
  props: ['fleet', 'airframes', 'airlines', 'loading'],
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalFleet: {
      get: function(){
        console.log(this.fleet)
        let fleet =  this.fleet.map(aircraft => ({
          ...aircraft,
          flight_time: this.flightTime(aircraft),
          location: this.location(aircraft),
          flight_count: this.flightCount(aircraft)
        }))

        const airports = fleet.map(aircraft => aircraft.location).filter(airport => {
          if (airport !== "N/A"){
            return airport
          }
        })
        this.getAirportData(airports)

        return fleet
      },
      set: function(val){
        this.$emit('update:fleet', val)
      }
    },
    airlineUnique: function(){
      return [...new Set (this.internalFleet.map(aircraft => {
        return aircraft['airline']['icao']
      }))].length
    },
    fleetLocations: function(){
      let fleetLocation = {}

      this.internalFleet.forEach(d => {
        const airport = d['location']
        const registration = d['aircraft']['registration']
        const airline = d['airline']['icao']
        const airframeName = d['airframe']['icao']

        if (airport === "N/A"){
          return
        }

        const label = "[" + registration + "] " + " " + airline + " " + airframeName
        if (airport in fleetLocation){
          fleetLocation[airport].push(label)
        }else{
          fleetLocation[airport] = [label]
        }
      })

      let fleetLocations = []

      for (let [airport, registrations] of Object.entries(fleetLocation)) {
        if (airport in this.airportData) {
          const airportData = this.airportData[airport]
          if (airportData != null) {
            const airportLatLng = this.getLatLng(airportData['airport_ref_latitude'], airportData['airport_ref_longitude'])

            fleetLocations.push({
              'airport': airportData,
              'label': registrations,
              'latLng': airportLatLng
            })
          }
        }
      }

     return fleetLocations
    },
    aircraftTypes: function(){
      let airframes = {}

      this.internalFleet.forEach(aircraft => {
        const airframe = aircraft['airframe']['type']
        airframes[airframe] = 0
      })

      return Object.keys(airframes).length
    },
    fleetShowLabels: function(){
      if (this.zoom > 4){
        return this.fleetLocations
      }
      return []
    },
    fleetStandard: function(){
      if (this.zoom <=4){
        return this.fleetLocations
      }
      return []
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LCircleMarker,
    FleetModal,
    AddAirframeModal,
    AddAircraftModal,
    ConfirmDialog,
  },
  created: function(){
  },
  data: function(){
    return {
      search: "",
      headers: [
        {
          text: 'Airline',
          value: 'airline.name'
        },
        {
          text: 'Airframe',
          value: 'airframe.name',
          class: 'test'
        },
        {
          text: 'Registration',
          value: 'aircraft.registration'
        },
        {
          text: 'Flight Time (Hours)',
          value: 'flight_time'
        },
        {
          text: 'Flight Count',
          value: 'flight_count'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
          width: '1%'
        }
      ],
      aircraftIDToEdit: "",
      aircraftIDToView: "",
      dialogState: false,
      aircraftDialogState: false,
      airportData: {},
      airportDataLoading: true,

      mapCenter: L.latLng(0,0),
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom:2,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',

      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [26, 26],
        iconAnchor: [13, 13]
      }),
    }
  },
  watch: {
    dialogState: function(val){
      if (!val){
        this.aircraftIDToEdit = ""
      }
    },
  },
  methods: {
    deleteAircraftWithPopup: function(aircraftID){
      this.$refs.confirmDialogRef.run().then(function(confirmRes){
        if (confirmRes){
          this.deleteAircraft(aircraftID)
        }
      }.bind(this))
    },
    deleteAircraft: function (aircraftID){
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/deleteAircraft?aircraftID="+aircraftID, requestOptions)
        .then(response => response.json())
        .then(function (){
          this.internalFleet = this.internalFleet.filter(d => d.aircraft.id !== aircraftID)
        }.bind(this))
    },
    openEditAircraft: function (aircraftID){
      this.aircraftIDToEdit = aircraftID
      this.dialogState = true
    },
    openViewAircraft: function(aircraftID){
      this.aircraftIDToView = aircraftID
      this.aircraftDialogState = true
    },
    completedFlights: function(aircraft){
      return aircraft['aircraft_flights'].filter(flight => !flight['in_flight'])
    },
    lastFlight: function(aircraft){
      const flights = this.completedFlights(aircraft)
      if (flights.length === 0){
        return []
      }
      return flights[flights.length - 1]
    },
    location: function(aircraft){
      if (this.lastFlight(aircraft).length === 0){
        return "N/A"
      }
      return this.lastFlight(aircraft).destination
    },
    flightTime: function(aircraft){
      const flightTimes = this.completedFlights(aircraft).map(d => d['in_flight_length'])
      return Shared.methods.msToHours(flightTimes.reduce((a, b) => a+b, 0))
    },
    flightCount: function(aircraft){
      return this.completedFlights(aircraft).length
    },
    mutateMap: function(){
      if ('mapFleet' in this.$refs) {
        this.$refs.mapFleet.mapObject.invalidateSize()
      }
    },
    getAirportData: function(airport_array){
      if (airport_array.length === 0){
        return {}
      }
      const distinctAirports = [... new Set(airport_array)]
      return fetch(this.$baseURL + "/airport?airport=" + distinctAirports.join(","))
        .then((response) => {
          if (response.ok){
            return  response.json().then(d => {
              if (d != null) {
                for (const [icao, data] of Object.entries(d)) {
                  this.airportData[icao] = data
                  this.airportData = Object.assign({}, this.airportData)
                }
                this.airportDataLoading = false
              }
            })
          }
        })
    },
    getLatLng: function(lat, lng){
      return L.latLng(lat, lng)
    },
    rowClass: function(){
      return "singleRow"
    }
  }
}
</script>

<style scoped>
  .actionButtons .v-btn{
    margin:5px;
  }
</style>

<style>
  .singleRow{
    white-space: nowrap;
  }
</style>
