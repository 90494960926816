<template>
  <v-dialog v-model="internalDialogState" style="z-index: 20" width="500">
    <v-card>
      <v-card-title class="headline">
        Airline
      </v-card-title>
      <v-card-text>
        <v-form ref="addAirlineForm">
          <v-text-field :label="'Name'" v-model="airlineName" :rules="requiredFieldRule(airlineName)" required/>
          <v-text-field :label="'ICAO'" v-model="airlineICAO" :rules="requiredFieldRule(airlineICAO)" required/>
          <v-text-field :label="'IATA'" v-model="airlineIATA" :rules="requiredFieldRule(airlineIATA)" required/>
          <div class="actionsContainer">
            <template v-if="editState">
              <v-btn color="success" v-on:click="editAirline(passedInAirlineID, airlineName, airlineICAO, airlineIATA)">Edit and close</v-btn>
            </template>
            <template v-else>
              <v-btn color="success" v-on:click="addAirline(airlineName, airlineICAO, airlineIATA)">Add and close</v-btn>
            </template>
            <v-btn color="error" v-on:click="internalDialogState = false" :disabled="saving">Close</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "@/components/Shared";

export default {
  name: "AddAirlineModal",
  props: ['dialogState', 'airlines', 'passedInAirlineID'],
  data: function (){
    return {
      airlineName: "",
      airlineICAO: "",
      airlineIATA: "",

      saving: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    internalAirlines: {
      get: function(){
        return this.airlines
      },
      set: function(val){
        this.$emit('update:airlines', val)
      }
    },
    editState: function(){
      return this.passedInAirlineID !== ""
    }
  },
  watch: {
    internalDialogState: function(val){
      if (!val){
        this.$emit('update:passedInAirlineID', "")
        this.airlineName = ''
        this.airlineICAO = ''
        this.airlineIATA = ''
        this.$refs.addAirlineForm.resetValidation()
      }
    },
    passedInAirlineID: function(val){
      if (val !== ""){
        const airlinesFiltered = this.airlines.filter(airline => {
          return airline.custom && airline.id === val
        })
        if (airlinesFiltered.length > 0){
          const airline = airlinesFiltered[0]
          this.airlineName = airline['name']
          this.airlineICAO = airline['icao']
          this.airlineIATA = airline['iata']
        }
      }
    }
  },
  methods: {
    requiredFieldRule: function(element){
      return Shared.methods.requiredFieldRule(element)
    },
    addAirline: function(name, icao, iata){
      const valid = this.$refs.addAirlineForm.validate()
      if (!valid){
        return
      }

      const payloadObj = {
        name: name,
        icao: icao,
        iata: iata
      }
      const body = JSON.stringify(payloadObj)

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: body
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/addCustomAirline", requestOptions)
        .then(response => {
          this.internalDialogState = false
          if (!response.ok){
            console.error("not okay")
            return
          }
          return response.json()
        }).then(data => {
          if (data !== null){
            this.internalAirlines.push(data)
          }
      })
    },
    editAirline: function(id, name, icao, iata){
      const valid = this.$refs.addAirlineForm.validate()
      if (!valid){
        return
      }

      const payloadObj = {
        airlineID: id,
        name: name,
        icao: icao,
        iata: iata
      }
      const body = JSON.stringify(payloadObj)

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: body
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/editCustomAirline", requestOptions)
        .then(response => {
          this.internalDialogState = false
          if (!response.ok){
            console.error("not okay")
            return
          }
          return response.json()
        }).then(data => {
          console.log(data)
      })
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.actionsContainer .v-btn{
  margin: 5px;
}
</style>
