<template>
  <v-container>
    <h1>Airframes & Custom Airlines</h1>
    <v-row>
      <v-col md="6" cols="12">
        <v-card>
          <v-card-title>
            SimBrief Airframes
            <v-spacer/>
            <v-btn v-on:click="airframeDialogState = true" color="success">Add Airframe</v-btn>
          </v-card-title>
          <v-data-table :items="customAirframes" :headers="headers" :loading="loading">
            <template #item.actions="{ item }">
              <v-btn color="warning" v-on:click="editAirframe(item.id)" style="margin-right: 5px">Edit</v-btn>
              <v-btn color="error" v-on:click="deleteAirframeWithPopup(item.id);"  style="margin-right: 5px">Delete</v-btn>
            </template>
          </v-data-table>
        </v-card>
        <ConfirmDialog ref="confirmDialogRef"/>
        <AddAirframeModal :airframes.sync="airframes" :dialogState.sync="airframeDialogState" :passedInAirframeID.sync="airframeIDToPassIn"/>
      </v-col>

      <v-col md="6" cols="12">
        <v-card>
          <v-card-title>
            Custom Airlines
            <v-spacer/>
            <v-btn v-on:click="airlineModal = true" color="success">Add Airline</v-btn>
          </v-card-title>
          <v-data-table :items="customAirlines" :headers="airlineHeaders">
            <template #item.actions="{ item }">
              <v-btn color="warning" v-on:click="editAirline(item.id);"  style="margin-right: 5px">Edit</v-btn>
              <v-btn color="error" v-on:click="deleteAirline(item.id);"  style="margin-right: 5px">Delete</v-btn>
            </template>
          </v-data-table>
        </v-card>
        <AddAirlineModal :dialog-state.sync="airlineModal" :airlines.sync="internalAirlines" :passedInAirlineID.sync="airlineIDToPassIn"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import AddAirframeModal from "./AddAirframeModal";
import ConfirmDialog from "./ConfirmDialog";
import AddAirlineModal from "./AddAirlineModal";

export default {
  name: "FleetAirframe",
  components: {
    AddAirframeModal,
    ConfirmDialog,
    AddAirlineModal
  },
  props: ['airframes', 'airlines', 'loading'],
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    customAirframes: function(){
      return this.internalAirframes.filter(data => data['is_custom'])
    },
    internalAirframes: {
      get: function(){
        return this.airframes
      },
      set: function(val){
        this.$emit('update:airframes', val)
      }
    },
    internalAirlines: {
      get: function(){
        return this.airlines
      },
      set: function(val){
        this.$emit('update:airlines', val)
      }
    },
    customAirlines: function(){
      return this.internalAirlines.filter(d => d.custom)
    }
  },
  data: function(){
    return {
      headers: [
        {
          text: "Airframe",
          value: "name"
        },
        {
          text: "ICAO",
          value: 'icao'
        },
        {
          text: "SimBrief ID / Code",
          value: "type"
        },
        {
          text: "Actions",
          value: "actions",
          align: 'right'
        }
      ],
      airframeDialogState: false,
      airframeIDToPassIn: "",

      airlineModal: false,
      airlineIDToPassIn: "",
      airlineHeaders: [
        {
          text: "Airline",
          value: "name"
        },
        {
          text: "ICAO",
          value: 'icao'
        },
        {
          text: "IATA",
          value: "iata"
        },
        {
          text: "Actions",
          value: "actions",
          align: 'right'
        }
      ],
    }
  },
  methods: {
    deleteAirframeWithPopup: function(airframeID){
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      console.log(airframeID)
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/removeAirframe?airframeID="+airframeID, requestOptions)
        .then(response => response.json())
        .then(function (data){
          const error = data['error']
          if (error === ""){
            this.internalAirframes = this.internalAirframes.filter(data => data.id !== airframeID)
          }
        }.bind(this))
    },
    editAirframe: function(airframeID){
      this.airframeIDToPassIn  = airframeID
      this.airframeDialogState = true
    },
    editAirline: function(airlineID){
      this.airlineIDToPassIn = airlineID
      this.airlineModal = true
    },
    deleteAirline: function(id){
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/" + this.currentUser['user'] + "/deleteCustomAirline?airlineID="+id, requestOptions)
        .then(response => {
          if (!response.ok){
            console.error("Unable to delete airline")
            return
          }
          this.internalAirlines = this.internalAirlines.filter(d => d.id !== id)
        })
    }
  }
}
</script>

<style scoped>
  .actionButtons .v-btn{
    margin:5px;
  }
</style>
